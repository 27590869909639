// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: '/app'
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-¬-a3a7-d851eb7e17f0',
  purchase: '/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },
  app: {
    itemOne: path(ROOTS.app, '/item-one'),
    itemTwo: path(ROOTS.app, '/item-two'),
    itemThree: path(ROOTS.app, '/item-three')
  }
};
