import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.general.dashboard,
    //   component: lazy(() => import('src/views/DashboardAppView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.general.ecommerce,
    //   component: lazy(() => import('src/views/DashboardEcommerceView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.general.analytics,
    //   component: lazy(() => import('src/views/DashboardAnalyticsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.root,
    //   component: () => <Redirect to={PATH_APP.general.root} />
    // },

    // APP
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.app.itemOne,
    //   component: lazy(() => import('src/views/ItemOneView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app.itemTwo,
    //   component: lazy(() => import('src/views/ItemTwoView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app.itemThree,
    //   component: lazy(() => import('src/views/ItemThreeView'))
    // },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
