import React from 'react';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store, persistor } from './redux/store';
import routes, { renderRoutes } from 'src/routes';
import ScrollToTop from 'src/components/ScrollToTop';
import LoadingScreen from 'src/components/LoadingScreen';
import { PersistGate } from 'redux-persist/lib/integration/react';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <Router history={history}>
            <ScrollToTop />
            {renderRoutes(routes)}
          </Router>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
