import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  page: <MIcon src={path('ic_page')} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.general.root,
        items: [
          {
            title: 'app',
            href: PATH_APP.general.root
          },
          {
            title: 'e-commerce',
            href: PATH_APP.general.ecommerce
          },
          {
            title: 'analytics',
            href: PATH_APP.general.analytics
          }
        ]
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'Item One',
        href: PATH_APP.app.itemOne,
        icon: ICONS.page
      },
      {
        title: 'Item Two',
        href: PATH_APP.app.itemTwo,
        icon: ICONS.page
      },
      {
        title: 'Item Three',
        href: PATH_APP.app.itemThree,
        icon: ICONS.page
      }
    ]
  }
];

export default navConfig;
